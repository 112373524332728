import SponsorLogo from '../../components/SponsorLogo/SponsorLogo';

const racePageSponsorGroup = ({
    sponsors,
    sponsorLevel,
    hasSponsorsHeader,
}) => {
    const sponsorLogos = sponsors.map((sponsor, id) => {
        return (
            <SponsorLogo
                key={id}
                sponsor={sponsor}
                containerClasses='rounded-md shadow-md p-4 dark:bg-gray-800 flex flex-col h-60'
                imageWrapperClasses='flex-grow'
                textClasses='pt-2'
            />
        );
    });
    let sponsorLevelElement = null;
    if (sponsorLevel && hasSponsorsHeader) {
        sponsorLevelElement = <h3>{sponsorLevel}</h3>;
    } else if (sponsorLevel) {
        sponsorLevelElement = <h2>{sponsorLevel}</h2>;
    }
    return (
        <>
            {sponsorLevelElement}
            <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-1'>
                {sponsorLogos}
            </div>
        </>
    );
};

export default racePageSponsorGroup;
