// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SponsorLogo from '../SponsorLogo/SponsorLogo';

// Services, data, and media
import { classNames, shuffleArray } from '../../utils/general';
import { getSponsors } from '../../services/sponsorsService';
import { getISODate } from '../../utils/datesAndTimes';
import {
    setSponsors,
    setPresentingSponsors,
    setSponsorsExist,
} from '../../redux/actions/state';

class Sponsors extends Component {
    state = {
        loading: true,
        error: false,
        allSponsorsData: [],
        presentingSponsors: [],
        sponsors: [],
    };

    componentDidMount() {
        if (
            this.props.reduxSponsors.length > 0 ||
            this.props.reduxPresentingSponsors.length > 0
        ) {
            // Load sponsors from Redux if we've already fetched them
            this.setSponsorsFromRedux();
            this.setState({ loading: false });
        } else {
            // Fetch sponsors from Strapi
            const date = getISODate();
            getSponsors(date)
                .then((response) => {
                    this.setState(
                        {
                            loading: false,
                            allSponsorsData: response.data?.data,
                        },
                        this.sortAndRandomizeSponsors
                    );
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: true,
                    });
                });
        }
    }

    setSponsorsFromRedux = () => {
        this.setState({
            sponsors: this.props.reduxSponsors,
            presentingSponsors: this.props.reduxPresentingSponsors,
        });
    };

    updateReduxSponsors = () => {
        const { sponsors, presentingSponsors } = this.state;
        this.props.setSponsors(sponsors);
        this.props.setPresentingSponsors(presentingSponsors);
        const sponsorsExist =
            sponsors.length > 0 || presentingSponsors.length > 0;
        this.props.setSponsorsExist(sponsorsExist);
    };

    sortAndRandomizeSponsors = () => {
        const { allSponsorsData } = this.state;
        const presentingSponsors = allSponsorsData.filter(
            (sponsor) => sponsor.attributes.is_presenting_sponsor
        );
        const sponsors = allSponsorsData.filter(
            (sponsor) => !sponsor.attributes.is_presenting_sponsor
        );
        shuffleArray(presentingSponsors);
        shuffleArray(sponsors);
        this.setState(
            {
                presentingSponsors,
                sponsors,
            },
            this.updateReduxSponsors
        );
    };

    render() {
        const { presentingSponsors, sponsors, loading, error } = this.state;

        if (loading) {
            return <LoadingSpinner classes='w-full flex justify-center my-4' />;
        }

        if (error) {
            return (
                <div className='w-full flex justify-center my-4'>
                    <h3>Unable to load sponsors</h3>
                </div>
            );
        }

        if (sponsors.length === 0 && presentingSponsors.length === 0) {
            return null;
        }

        const presentingSponsorsLogos = presentingSponsors.map(
            (sponsor, idx) => {
                return (
                    <div
                        key={idx}
                        className={classNames(
                            'w-full py-2',
                            this.props.vertical ? 'first:pt-0 last:pb-0' : ''
                        )}
                    >
                        <SponsorLogo
                            sponsor={sponsor.attributes}
                            containerClasses={classNames(
                                'flex flex-row flex-wrap items-center justify-evenly',
                                !this.props.vertical ? 'lg:flex-nowrap' : ''
                            )}
                            imageWrapperClasses={classNames(
                                !this.props.vertical ? 'px-4' : ''
                            )}
                            textClasses={classNames(
                                'text-xl',
                                !this.props.vertical ? 'p-4' : 'pt-4'
                            )}
                        />
                    </div>
                );
            }
        );

        const sponsorsLogos = sponsors.map((sponsor, idx) => {
            return (
                <div
                    key={idx}
                    className={classNames(
                        'w-full py-2',
                        !this.props.vertical
                            ? 'px-0 sm:px-2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'
                            : 'first:pt-0 last:pb-0'
                    )}
                >
                    <SponsorLogo
                        sponsor={sponsor.attributes}
                        containerClasses='rounded-md shadow-md p-4 dark:bg-gray-800 flex flex-col h-60'
                        imageWrapperClasses='flex-grow'
                        textClasses='pt-2'
                    />
                </div>
            );
        });

        return (
            <div className='w-full max-w-7xl mx-auto space-y-4'>
                {presentingSponsors.length > 0 && (
                    <div className='mx-auto flex flex-wrap'>
                        {presentingSponsorsLogos}
                    </div>
                )}
                {presentingSponsors.length > 0 && sponsors.length > 0 && (
                    <hr className='w-[95%] lg:w-full mx-auto' />
                )}
                {sponsorsLogos.length > 0 && (
                    <div className='mx-auto flex flex-wrap justify-evenly'>
                        {sponsorsLogos}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reduxSponsors: state.state.sponsors,
        reduxPresentingSponsors: state.state.presentingSponsors,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSponsors: (sponsors) => dispatch(setSponsors(sponsors)),
        setPresentingSponsors: (presentingSponsors) =>
            dispatch(setPresentingSponsors(presentingSponsors)),
        setSponsorsExist: (sponsorsExist) =>
            dispatch(setSponsorsExist(sponsorsExist)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);
