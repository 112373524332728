// Libraries
import { Link } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

// Components
import LinkButton from '../../components/LinkButton/LinkButton';
import ContactCard from '../CalendarPage/ContactCard';
import ResponsiveMedia from '../../components/ResponsiveMedia/ResponsiveMedia';

// Services, data, and media
import { classNames, isNullUndefined } from '../../utils/general';
import {
    getPhotoPositionClass,
    getBackgroundGradientClass,
} from '../../utils/styles';
import {
    CalendarIcon,
    MapPinIcon,
    FlagIcon,
    ClockIcon,
    InformationCircleIcon,
    UserCircleIcon,
    Bars4Icon,
} from '@heroicons/react/24/outline';
const gfm = require('remark-gfm');

const RunningGroupCard = ({ info, sponsorsExist }) => {
    if (isNullUndefined(info) || isNullUndefined(info.attributes)) {
        return null;
    }
    const {
        photo_position,
        days,
        links,
        location_link,
        location,
        name,
        time,
        frequency_description,
        distance,
        description,
    } = info.attributes;
    const photoPositionClass = getPhotoPositionClass(photo_position);

    let daysText = null;
    if (!isNullUndefined(days) && days !== '') {
        const daysArr = days.split(',');
        if (daysArr.length === 1) {
            daysText = `${daysArr[0]}s`;
        } else if (daysArr.length === 2) {
            daysText = `${daysArr[0]}s & ${daysArr[1]}s`;
        } else {
            const daysArrNew = daysArr.map((day) => `${day}s`);
            daysText = daysArrNew.join(', ');
        }
    }

    let linkButtons = null;
    if (!isNullUndefined(links)) {
        linkButtons = links.map((link) => (
            <LinkButton link={link} key={link.id} />
        ));
    }

    let locationElement = null;
    if (!isNullUndefined(location) && !isNullUndefined(location_link)) {
        if (location_link.includes('http')) {
            // External
            locationElement = (
                <a
                    className='hover:underline'
                    href={location_link}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {location}
                </a>
            );
        } else {
            // Internal
            locationElement = (
                <Link to={location_link} className='hover:underline'>
                    {location}
                </Link>
            );
        }
    } else if (!isNullUndefined(location)) {
        locationElement = <span>{location}</span>;
    }

    const contacts = info.attributes.contacts?.data;
    let contactCards = null;
    if (!isNullUndefined(contacts) && contacts.length > 0) {
        contactCards = contacts.map((contact, idx) => (
            <ContactCard key={contact.id} contact={contact} />
        ));
    }

    const photo = info.attributes.photo?.data;
    const backgroundGradientClass = getBackgroundGradientClass(photo, info.id);

    return (
        <div
            className={classNames(
                'w-full sm:w-1/2 px-3 my-3',
                !sponsorsExist && 'lg:w-1/3'
            )}
        >
            <div className='h-full bg-gray-50 dark:bg-gray-800 rounded-xl shadow-xl overflow-hidden'>
                {photo ? (
                    <ResponsiveMedia
                        media={photo}
                        classes={classNames(
                            photoPositionClass,
                            'h-48 w-full bg-no-repeat bg-cover'
                        )}
                        backgroundImage={true}
                    />
                ) : (
                    <div
                        className={classNames(
                            'h-48 w-full',
                            backgroundGradientClass
                        )}
                    ></div>
                )}
                <div className='h-[calc(100%-12rem)] flex flex-col justify-between'>
                    <div className='p-4'>
                        {!isNullUndefined(name) ? (
                            <h4 className='font-serif font-semibold mb-2'>
                                {name}
                            </h4>
                        ) : null}
                        <Tab.Group
                            as='div'
                            className='flex justify-between'
                            vertical
                        >
                            <Tab.Panels className='pr-2 min-w-0'>
                                <Tab.Panel>
                                    {!isNullUndefined(days) ? (
                                        <div className='flex items-center mb-1'>
                                            <div className='w-6 mr-2'>
                                                <CalendarIcon
                                                    className='h-6 w-6 heroicon-stroke-w-2'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <span>{daysText}</span>
                                        </div>
                                    ) : null}
                                    {!isNullUndefined(frequency_description) ? (
                                        <div className='my-1 ml-8'>
                                            <span className='italic'>
                                                {frequency_description}
                                            </span>
                                        </div>
                                    ) : null}
                                    {!isNullUndefined(time) ? (
                                        <div className='flex my-1'>
                                            <div className='w-6 mr-2'>
                                                <ClockIcon
                                                    className='h-6 w-6 heroicon-stroke-w-2'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <span>{time}</span>
                                        </div>
                                    ) : null}
                                    {!isNullUndefined(distance) ? (
                                        <div className='flex items-center my-1'>
                                            <div className='w-6 mr-2'>
                                                <FlagIcon
                                                    className='h-6 w-6 heroicon-stroke-w-2'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <span>{distance}</span>
                                        </div>
                                    ) : null}
                                    {!isNullUndefined(locationElement) ? (
                                        <div className='flex items-center my-1'>
                                            <div className='w-6 mr-2'>
                                                <MapPinIcon
                                                    className='h-6 w-6 heroicon-stroke-w-2'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            {locationElement}
                                        </div>
                                    ) : null}
                                </Tab.Panel>
                                <Tab.Panel>
                                    {!isNullUndefined(description) ? (
                                        <div className='prose dark:prose-invert'>
                                            <ReactMarkdown
                                                children={description}
                                                remarkPlugins={[gfm]}
                                                linkTarget={(
                                                    href,
                                                    children,
                                                    title
                                                ) =>
                                                    href.includes('http')
                                                        ? '_blank'
                                                        : null
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <p>No description available</p>
                                    )}
                                </Tab.Panel>
                                <Tab.Panel>
                                    {!isNullUndefined(contactCards) ? (
                                        <div className='flex flex-col -ml-1 -mt-1'>
                                            {contactCards}
                                        </div>
                                    ) : (
                                        <p>No contacts available</p>
                                    )}
                                </Tab.Panel>
                            </Tab.Panels>
                            <Tab.List className='grow-0 flex flex-col pl-2 space-y-1'>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            'w-full p-2 text-gray-900 dark:text-gray-50 rounded-lg',
                                            selected
                                                ? 'bg-gray-200 dark:bg-gray-700'
                                                : 'hover:bg-gray-200 dark:hover:bg-gray-700'
                                        )
                                    }
                                >
                                    <InformationCircleIcon
                                        className='h-6 w-6 heroicon-stroke-w-2'
                                        aria-hidden='true'
                                    />
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            'w-full p-2 text-gray-900 dark:text-gray-50 rounded-lg',
                                            selected
                                                ? 'bg-gray-200 dark:bg-gray-700'
                                                : 'hover:bg-gray-200 dark:hover:bg-gray-700'
                                        )
                                    }
                                >
                                    <Bars4Icon
                                        className='h-6 w-6 heroicon-stroke-w-2'
                                        aria-hidden='true'
                                    />
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                            'w-full p-2 text-gray-900 dark:text-gray-50 rounded-lg',
                                            selected
                                                ? 'bg-gray-200 dark:bg-gray-700'
                                                : 'hover:bg-gray-200 dark:hover:bg-gray-700'
                                        )
                                    }
                                >
                                    <UserCircleIcon
                                        className='h-6 w-6 heroicon-stroke-w-2'
                                        aria-hidden='true'
                                    />
                                </Tab>
                            </Tab.List>
                        </Tab.Group>
                    </div>
                    {!isNullUndefined(linkButtons) && linkButtons.length > 0 ? (
                        <div className='flex flex-wrap items-start -ml-1 -mt-1 p-4'>
                            {linkButtons}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        sponsorsExist: state.state.sponsorsExist,
    };
};

export default connect(mapStateToProps)(RunningGroupCard);
