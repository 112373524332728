// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import SponsorLogo from '../SponsorLogo/SponsorLogo';

// Services, data, and media
import { classNames, shuffleArray } from '../../utils/general';
import { getPartners } from '../../services/sponsorsService';
import { getISODate } from '../../utils/datesAndTimes';
import { setPartners } from '../../redux/actions/state';

class PartnersCarousel extends Component {
    state = {
        loading: true,
        error: false,
        partnersData: [],
        partnersDataDouble: [],
        index: 0,
        timer: null,
    };

    componentDidMount() {
        if (this.props.reduxPartnersData.length > 0) {
            // Load partners from Redux if we've already fetched them
            this.setPartnersFromRedux();
            this.setState({ loading: false }, this.setTimer);
        } else {
            // Fetch partners from Strapi
            const date = getISODate();
            getPartners(date)
                .then((response) => {
                    const data = response.data?.data;
                    this.setState(
                        {
                            loading: false,
                            partnersData: data,
                            partnersDataDouble: [...data, ...data],
                        },
                        this.sortAndRandomizePartners
                    );
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: true,
                    });
                });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    sortAndRandomizePartners = () => {
        const { partnersData } = this.state;
        shuffleArray(partnersData);
        const newPartnersData = [...partnersData];
        this.setState(
            {
                partnersData: newPartnersData,
                partnersDataDouble: [...newPartnersData, ...newPartnersData],
            },
            () => {
                this.updateReduxPartners();
                this.setTimer();
            }
        );
    };

    setPartnersFromRedux = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                partnersData: this.props.reduxPartnersData,
                partnersDataDouble: [
                    ...this.props.reduxPartnersData,
                    ...this.props.reduxPartnersData,
                ],
            };
        });
    };

    updateReduxPartners = () => {
        const { partnersData } = this.state;
        this.props.setPartners(partnersData);
    };

    progressCarousel = () => {
        const { index, partnersData } = this.state;

        let newIndex;
        if (index >= partnersData.length) {
            newIndex = 0;
        } else {
            newIndex = index + 1;
        }

        this.setState({ index: newIndex }, () => {
            if (newIndex === 0) {
                this.resetTimer();
            }
        });
    };

    setTimer = () => {
        this.setState({ timer: setInterval(this.progressCarousel, 5000) });
    };

    resetTimer = () => {
        if (this.state.timer) {
            clearInterval(this.state.timer);
        }
        setTimeout(() => {
            this.progressCarousel();
            this.setState({ timer: setInterval(this.progressCarousel, 5000) });
        }, 500);
    };

    getDiff = () => {
        // Calculate how much we move each partner (e.g. partner width)
        let diff;
        const screenWidthWithoutScrollbar =
            document.documentElement.clientWidth;
        let numPartners;
        /**
         * Max width is 1280px (7xl)
         * 1423px is the value above which 90% of the width is >= 1280px
         * Therefore we don't take 90% of the value
         * 0.9 * width = 1280 => width = 1423
         */
        if (screenWidthWithoutScrollbar >= 1423) {
            // 5 partners
            numPartners = 5;
            diff = 1280 / 5;
        } else if (window.innerWidth >= 1280) {
            // (xl) 5 partners 90% width of window / 5 = width * 0.9 * 0.2
            numPartners = 5;
            diff = screenWidthWithoutScrollbar * 0.9 * 0.2;
        } else if (window.innerWidth >= 1024) {
            // (lg) 4 partners 90% width of window / 4 = width * 0.9 * 0.25
            numPartners = 4;
            diff = screenWidthWithoutScrollbar * 0.9 * 0.25;
        } else if (window.innerWidth >= 768) {
            // (md) 3 partners 90% width of window / 3 = width * 0.9 * 1/3 = width * 0.3
            numPartners = 3;
            diff = screenWidthWithoutScrollbar * 0.3;
        } else if (window.innerWidth >= 640) {
            // (sm) 2 partners 90% width of window / 2 = width * 0.9 * 0.5
            numPartners = 2;
            diff = screenWidthWithoutScrollbar * 0.9 * 0.5;
        } else {
            // 1 partner 90% width of window = width * 0.9
            numPartners = 1;
            diff = screenWidthWithoutScrollbar * 0.9;
        }
        // Don't scroll carousel if the partners fit in the view
        if (this.state.partnersData.length <= numPartners) {
            diff = 0;
        }
        return [diff, numPartners];
    };

    render() {
        const { loading, error, partnersData, partnersDataDouble, index } =
            this.state;

        if (loading) {
            return <LoadingSpinner classes='w-full flex justify-center my-4' />;
        }

        if (error) {
            return (
                <div className='w-full flex justify-center my-4'>
                    <h3>Unable to load partners</h3>
                </div>
            );
        }

        if (partnersData.length === 0) {
            return null;
        }

        let [diff, numPartners] = this.getDiff();
        let translateValue = index * -diff;

        let transitionClasses = '';
        if (index > 0) {
            transitionClasses = 'transition-transform duration-500 ease-in-out';
        }

        let dataArray = partnersDataDouble;
        if (partnersData.length <= numPartners) {
            dataArray = partnersData;
        }

        let slideWidthsClasses = 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5';
        let slidesContainerWidthClass = 'w-full';
        if (partnersData.length === 4) {
            slideWidthsClasses = 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4';
            slidesContainerWidthClass = 'w-full xl:w-3/4';
        }
        if (partnersData.length === 3) {
            slideWidthsClasses = 'w-full sm:w-1/2 md:w-1/3';
            slidesContainerWidthClass = 'w-full lg:w-3/4 xl:w-2/3';
        }
        if (partnersData.length === 2) {
            slideWidthsClasses = 'w-full sm:w-1/2';
            slidesContainerWidthClass = 'w-full md:w-3/4 lg:w-2/3 xl:w-1/2';
        }
        if (partnersData.length === 1) {
            slideWidthsClasses = 'w-full';
            slidesContainerWidthClass =
                'w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-2/5';
        }

        const partners = dataArray.map((item, idx) => {
            return (
                <SponsorLogo
                    key={idx}
                    sponsor={item.attributes}
                    containerClasses={classNames(
                        slideWidthsClasses,
                        transitionClasses,
                        'px-4 whitespace-normal inline-flex flex-col h-60 align-middle'
                    )}
                    imageWrapperClasses='flex-grow'
                    textClasses='pt-2'
                    style={{
                        transform: `translateX(${translateValue}px)`,
                    }}
                />
            );
        });

        return (
            <div className='w-full my-4'>
                <div className='w-[90%] max-w-7xl mx-auto my-4'>
                    <div
                        className={classNames(
                            slidesContainerWidthClass,
                            'mx-auto whitespace-nowrap overflow-hidden'
                        )}
                    >
                        {partners}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reduxPartnersData: state.state.partners,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPartners: (partners) => dispatch(setPartners(partners)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnersCarousel);
