// Libraries
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

// Components
import PartnersCarousel from '../../components/PartnersCarousel/PartnersCarousel';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Sponsors from '../../components/Sponsors/Sponsors';

// Services, data, and media
import { getPage } from '../../services/pageService';
import { classNames, isNullUndefined } from '../../utils/general';
const gfm = require('remark-gfm');

class Page extends Component {
    state = {
        loading: true,
        error: false,
        errorMsg: '',
        pageData: null,
    };

    componentDidMount() {
        this.getPageData();
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ loading: true }, this.getPageData);
            window.scrollTo(0, 0);
        }
    }

    getPageData() {
        let slug = this.props.location?.pathname;
        if (!isNullUndefined(slug)) {
            if (slug.startsWith('/')) {
                slug = slug.substring(1);
            }
            getPage(slug)
                .then((response) => {
                    this.setState({
                        loading: false,
                        pageData: response.data.data,
                    });
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error: true,
                        errorMsg: error.response.statusText,
                    });
                });
        } else {
            this.setState({ pageData: null, errorMsg: 'Page not found' });
        }
    }

    render() {
        const { pageData, loading, error, errorMsg } = this.state;
        const { sponsorsExist } = this.props;

        if (loading) {
            return (
                <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />
            );
        }

        if (error || isNullUndefined(pageData)) {
            return (
                <div className='flex justify-center pt-12 lg:pt-48'>
                    <h3>{errorMsg}</h3>
                </div>
            );
        }

        const { name, content } = pageData.attributes;
        if (isNullUndefined(name) || isNullUndefined(content)) {
            return null;
        }
        return (
            <>
                <div
                    className={classNames(
                        'flex flex-wrap mx-auto',
                        sponsorsExist && 'max-w-7xl'
                    )}
                >
                    <div
                        className={classNames(
                            'w-full mx-auto p-4 prose dark:prose-invert',
                            sponsorsExist && 'lg:w-2/3 max-w-7xl'
                        )}
                    >
                        <h1>{name}</h1>
                        <ReactMarkdown
                            children={content}
                            remarkPlugins={[gfm]}
                            linkTarget={(href, children, title) =>
                                href.includes('http') ? '_blank' : null
                            }
                        />
                    </div>
                    {sponsorsExist && (
                        <>
                            <div className='hidden lg:block w-1/3 p-4'>
                                <Sponsors vertical={true} />
                            </div>
                            <div className='w-full lg:hidden p-4'>
                                <Sponsors />
                            </div>
                        </>
                    )}
                </div>
                <PartnersCarousel />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sponsorsExist: state.state.sponsorsExist,
    };
};

export default connect(mapStateToProps)(Page);
