// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

// Components
import EventDetail from './EventDetail';
import ListBox from '../../components/ListBox/ListBox';
import PartnersCarousel from '../../components/PartnersCarousel/PartnersCarousel';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { getEvents, getLatestEvent } from '../../services/eventsService';
import { customStyles } from '../../utils/dataTablesStyles';
import calendarColumns from './calendarColumns';
import { calendarConditionalRowStyles } from './calendarStyles';
import {
    monthNames,
    getISODate,
    getFutureISODate,
    currentYear,
    getYear,
} from '../../utils/datesAndTimes';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import Sponsors from '../../components/Sponsors/Sponsors';

class CalendarPage extends Component {
    state = {
        loading: true,
        error: false,
        calendarData: [],
        calendarDataGrouped: new Map(),
        selectedView: 'Upcoming',
        availableViews: ['Upcoming', `Full ${currentYear}`],
    };

    componentDidMount() {
        this.getCalendarData();
        // Update availableViews if there are events in future years
        getLatestEvent().then((response) => {
            const dateTime = response.data?.data[0].attributes.date_time;
            const latestYear = getYear(dateTime.split('T')[0]);
            const availableYearsArr = new Array(latestYear - currentYear + 1)
                .fill()
                .map((_, idx) => currentYear + idx);
            if (availableYearsArr.length > 1) {
                const availableViews = ['Upcoming'];
                availableYearsArr.forEach((year) => {
                    availableViews.push(`Full ${year}`);
                });
                this.setState({ availableViews });
            }
        });
        window.scrollTo(0, 0);
    }

    getCalendarData() {
        const { selectedView } = this.state;
        let startDate, endDate;

        if (selectedView === 'Upcoming') {
            startDate = getISODate();
            endDate = getFutureISODate(90); // Pull in entry lists for next 90 days
        } else {
            // e.g. 'Full 2022'
            const year = selectedView.split(' ')[1];
            startDate = `${year}-01-01`;
            endDate = `${year}-12-31`;
        }

        getEvents(startDate, endDate)
            .then((response) => {
                this.setState(
                    {
                        loading: false,
                        calendarData: response.data?.data,
                    },
                    this.groupEventsByMonth
                );
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    }

    groupEventsByMonth() {
        const { calendarData } = this.state;
        const calendarDataGrouped = new Map();
        calendarData.forEach((event) => {
            const dateObj = new Date(event.attributes.date_time);
            const month = monthNames[dateObj.getMonth()];
            if (!calendarDataGrouped.has(month)) {
                calendarDataGrouped.set(month, []);
            }
            calendarDataGrouped.get(month).push(event);
        });
        this.setState({ calendarDataGrouped });
    }

    setCalendarView = (view) => {
        this.setState(
            { selectedView: view, loading: true },
            this.getCalendarData
        );
    };

    render() {
        const {
            calendarDataGrouped,
            selectedView,
            availableViews,
            loading,
            error,
        } = this.state;
        const { theme, sponsorsExist } = this.props;

        if (loading) {
            return (
                <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />
            );
        }

        if (error) {
            return (
                <div className='flex justify-center pt-12 lg:pt-48'>
                    <h3>An error has occurred!</h3>
                </div>
            );
        }

        if (calendarDataGrouped.size > 0) {
            let monthDataTables = [];
            calendarDataGrouped.forEach((eventsArr, month) => {
                monthDataTables.push(
                    <DataTable
                        key={month}
                        title={month}
                        columns={calendarColumns}
                        data={eventsArr}
                        customStyles={customStyles}
                        theme={theme === 'dark' ? 'dark' : 'light'}
                        expandableRows
                        expandableRowsComponent={EventDetail}
                        striped
                        responsive={false}
                        conditionalRowStyles={calendarConditionalRowStyles}
                    />
                );
            });

            // Split data tables array into 2 so we can display ads in between
            const monthDataTables1 = monthDataTables.slice(0, 1);
            const monthDataTables2 = monthDataTables.slice(1);

            let headerText = 'Upcoming Events';
            if (selectedView !== 'Upcoming') {
                const year = selectedView.split(' ')[1];
                headerText = `${year} Calendar`;
            }

            return (
                <div className='space-y-4'>
                    <div className='w-full mx-auto max-w-7xl'>
                        <div className='flex flex-wrap justify-between items-center mb-2 pt-4 px-4'>
                            <h1 className='text-4xl font-bold py-2 mr-2'>
                                {headerText}
                            </h1>
                            <div className='flex flex-wrap items-center -ml-2'>
                                <div className='relative w-52 h-14 py-2 mx-2'>
                                    <ListBox
                                        options={availableViews}
                                        onChange={this.setCalendarView}
                                        selectedOption={selectedView}
                                        widthClass='w-52'
                                    />
                                </div>
                                <Link
                                    to='/calendar/add'
                                    className='flex text-gray-50 dark:text-gray-900 bg-gray-700 dark:bg-gray-300 px-3 py-2 rounded-md text-base font-serif font-semibold no-underline mx-2'
                                >
                                    ADD EVENT
                                    <div className='w-6 ml-2'>
                                        <PlusCircleIcon
                                            className='h-6 w-6'
                                            aria-hidden='true'
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <hr className='mb-2 border-gray-300 dark:border-gray-700 px-4' />
                        <div className='pt-2'>{monthDataTables1}</div>
                    </div>
                    {sponsorsExist && (
                        <div className='w-full px-4 mx-auto'>
                            <Sponsors />
                        </div>
                    )}
                    <div className='w-full mx-auto max-w-7xl'>
                        {monthDataTables2}
                    </div>
                    <PartnersCarousel />
                </div>
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
        sponsorsExist: state.state.sponsorsExist,
    };
};

export default connect(mapStateToProps)(CalendarPage);
