import { setTheme, setThemeInLocalStorage } from '../../utils/themeSwitcher';

const initialState = {
    theme: 'light',
    scrollPositionResults: 0,
    scrollPositionCourseRecords: 0,
    scrollPositionPersonalCourseRecords: 0,
    results: {},
    courseRecords: [],
    personalCourseRecords: [],
    monthDisclosures: {},
    selectedYear: new Date().getFullYear(),
    partners: [],
    sponsors: [],
    presentingSponsors: [],
    sponsorsExist: true,
};

const stateReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_INITIAL_THEME':
            const theme = setTheme();

            return {
                ...state,
                theme: theme,
            };

        case 'SET_THEME':
            setThemeInLocalStorage(action.theme);
            const newTheme = setTheme();

            return {
                ...state,
                theme: newTheme,
            };

        case 'SET_SCROLL_POSITION':
            switch (action.page) {
                case 'Results':
                    return {
                        ...state,
                        scrollPositionResults: action.scrollPosition,
                    };
                case 'CourseRecords':
                    return {
                        ...state,
                        scrollPositionCourseRecords: action.scrollPosition,
                    };
                case 'PersonalCourseRecords':
                    return {
                        ...state,
                        scrollPositionPersonalCourseRecords:
                            action.scrollPosition,
                    };
                default:
                    return {
                        ...state,
                    };
            }

        case 'SET_RESULTS':
            return {
                ...state,
                results: action.results,
            };

        case 'SET_COURSE_RECORDS':
            return {
                ...state,
                courseRecords: action.courseRecords,
            };

        case 'SET_PERSONAL_COURSE_RECORDS':
            return {
                ...state,
                personalCourseRecords: action.personalCourseRecords,
            };

        case 'SET_MONTH_DISCLOSURES':
            return {
                ...state,
                monthDisclosures: action.monthDisclosures,
            };

        case 'SET_SELECTED_YEAR':
            return {
                ...state,
                selectedYear: action.selectedYear,
            };

        case 'SET_PARTNERS':
            return {
                ...state,
                partners: action.partners,
            };

        case 'SET_SPONSORS':
            return {
                ...state,
                sponsors: action.sponsors,
            };

        case 'SET_PRESENTING_SPONSORS':
            return {
                ...state,
                presentingSponsors: action.presentingSponsors,
            };

        case 'SET_SPONSORS_EXIST':
            return {
                ...state,
                sponsorsExist: action.sponsorsExist,
            };

        default:
            return state;
    }
};

export default stateReducer;
