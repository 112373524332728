import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { classNames, isNullUndefined } from '../../utils/general';
import ResponsiveMedia from '../ResponsiveMedia/ResponsiveMedia';

const navigateToUrl = (url, history) => {
    if (url && url !== '') {
        if (url.includes('http')) {
            window.open(url, '_blank');
        } else {
            history.push(url);
        }
    }
};

const SponsorLogo = ({
    sponsor,
    containerClasses,
    imageWrapperClasses,
    textClasses,
    style,
    theme,
}) => {
    const history = useHistory();
    const {
        id,
        url,
        media,
        dark_media,
        text,
        background_color,
        dark_background_color,
        text_color,
        dark_text_color,
    } = sponsor;
    let backgroundColor = '';
    if (theme === 'light' && !isNullUndefined(background_color)) {
        backgroundColor = background_color;
    }
    if (theme === 'dark' && !isNullUndefined(dark_background_color)) {
        backgroundColor = dark_background_color;
    }
    let textColor = '';
    if (theme === 'light' && !isNullUndefined(text_color)) {
        textColor = text_color;
    }
    if (theme === 'dark' && !isNullUndefined(dark_text_color)) {
        textColor = dark_text_color;
    }
    return (
        <div
            key={id}
            className={classNames(
                !isNullUndefined(url) && url !== '' ? 'cursor-pointer' : '',
                'w-full',
                containerClasses
            )}
            style={{
                ...style,
                backgroundColor,
            }}
            onClick={() => navigateToUrl(url, history)}
        >
            {media || dark_media ? (
                <div className={classNames('min-h-0', imageWrapperClasses)}>
                    <ResponsiveMedia
                        media={
                            theme === 'dark' &&
                            !isNullUndefined(dark_media?.data)
                                ? dark_media?.data
                                : media?.data
                        }
                        classes='mx-auto h-full max-h-60 object-contain'
                    />
                </div>
            ) : null}
            {text ? (
                <p
                    className={classNames(
                        'not-prose text-center font-bold',
                        textClasses
                    )}
                    style={{ color: textColor }}
                >
                    {text}
                </p>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        theme: state.state.theme,
    };
};

export default connect(mapStateToProps)(SponsorLogo);
