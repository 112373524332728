const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getRacePage = (slug, currentYear) => {
    const query = qs.stringify({
        populate: {
            link: {
                fields: '*',
            },
            cover_media: {
                fields: '*',
            },
            information: {
                fields: '*',
                populate: {
                    media: {
                        fields: '*',
                    },
                },
            },
            events: {
                fields: [
                    'cancelled',
                    'date_time',
                    'distance',
                    'event_name',
                    'location',
                    'location_link',
                ],
                filters: {
                    date_time: {
                        $gte: `${currentYear}-01-01`,
                        $lte: `${currentYear + 1}-12-31`,
                    },
                },
                sort: ['date_time:asc'],
            },
            results: {
                fields: [
                    'link',
                    'credit',
                    'result_id',
                    'event_id',
                    'race_date',
                    'link_name',
                    'event_name',
                    'division_id',
                    'is_stats',
                ],
                sort: ['race_date:desc'],
            },
            entry_lists: {
                fields: ['entry_list_id', 'race_date', 'link_name'],
                sort: ['race_date:desc'],
            },
            sponsors: {
                fields: '*',
                populate: {
                    media: {
                        fields: '*',
                    },
                    dark_media: {
                        fields: '*',
                    },
                    sponsor_level: {
                        fields: '*',
                    },
                },
            },
        },
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/race-pages/${slug}?${query}`,
    });
};

module.exports = {
    getRacePage,
};
