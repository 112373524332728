import { classNames, isNullUndefined } from '../../utils/general';
import RacePageSponsorGroup from './RacePageSponsorGroup';

const otherCategory = '_other';

const racePageSponsors = ({ sponsors, sponsorsHeader }) => {
    if (isNullUndefined(sponsors) || sponsors.length === 0) {
        return null;
    }

    const hasSponsorsHeader =
        !isNullUndefined(sponsorsHeader) && sponsorsHeader !== '';

    // Group sponsors by sponsor level
    const sponsorLevels = new Map();
    sponsors.forEach((sponsor) => {
        const sponsorLevelData = sponsor.sponsor_level?.data;
        if (isNullUndefined(sponsorLevelData)) {
            // If sponsor level is not set, add to 'Other' category
            if (!sponsorLevels.has(otherCategory)) {
                sponsorLevels.set(otherCategory, [sponsor]);
            } else {
                sponsorLevels.get(otherCategory).push(sponsor);
            }
        } else {
            if (!sponsorLevels.has(sponsorLevelData.attributes.name)) {
                sponsorLevels.set(sponsorLevelData.attributes.name, [sponsor]);
            } else {
                sponsorLevels
                    .get(sponsorLevelData.attributes.name)
                    .push(sponsor);
            }
        }
    });

    // Render sponsor level text if there is at least one sponsor level other than 'Other'
    const renderSponsorLevelText = !(
        sponsorLevels.size === 1 && sponsorLevels.get(otherCategory)
    );

    const sponsorLevelGroups = [];
    sponsorLevels.forEach((sponsors, sponsorLevel) => {
        if (sponsorLevel === otherCategory) {
            sponsorLevel =
                sponsors.length === 1 ? 'Valued Sponsor' : 'Valued Sponsors';
        }

        sponsorLevelGroups.push(
            <RacePageSponsorGroup
                key={sponsorLevel}
                sponsors={sponsors}
                sponsorLevel={renderSponsorLevelText ? sponsorLevel : null}
                hasSponsorsHeader={hasSponsorsHeader}
            />
        );
    });

    return (
        <div
            className={classNames(
                'w-full',
                !hasSponsorsHeader && !renderSponsorLevelText ? 'mt-12' : ''
            )}
        >
            {hasSponsorsHeader ? <h2>{sponsorsHeader}</h2> : null}
            {sponsorLevelGroups}
        </div>
    );
};

export default racePageSponsors;
