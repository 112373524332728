const apiBaseUrl = require('../config/ApiUrl').apiBaseUrl;
const axios = require('axios');
const qs = require('qs');

const getSponsors = (date) => {
    const query = qs.stringify({
        filters: {
            is_partner: {
                $eq: false,
            },
            $and: [
                {
                    start_date: {
                        $lte: date,
                    },
                },
                {
                    end_date: {
                        $gte: date,
                    },
                },
            ],
        },
        populate: '*',
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/sponsors?${query}`,
    });
};

const getPartners = (date) => {
    const query = qs.stringify({
        filters: {
            is_partner: {
                $eq: true,
            },
            $or: [
                {
                    indefinite: {
                        $eq: true,
                    },
                },
                {
                    $and: [
                        {
                            start_date: {
                                $lte: date,
                            },
                        },
                        {
                            end_date: {
                                $gte: date,
                            },
                        },
                    ],
                },
            ],
        },
        populate: '*',
    });
    return axios({
        method: 'get',
        url: `${apiBaseUrl}/sponsors?${query}`,
    });
};

module.exports = {
    getSponsors,
    getPartners,
};
