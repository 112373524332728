// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import HeroMedia from './HeroMedia';
import MainCarousel from './MainCarousel';
import PartnersCarousel from '../../components/PartnersCarousel/PartnersCarousel';
import HomePageInformation from './HomePageInformation';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

// Services, data, and media
import { getISODate } from '../../utils/datesAndTimes';
import {
    getHomePageData,
    getHomePageCarouselItems,
} from '../../services/homePageService';
import Sponsors from '../../components/Sponsors/Sponsors';

class HomePage extends Component {
    state = {
        loading: true,
        error: false,
        homePageData: {},
        carouselData: [],
        adsData: [],
    };

    componentDidMount() {
        const date = getISODate();
        Promise.all([getHomePageData(), getHomePageCarouselItems(date)])
            .then((responses) => {
                this.setState({
                    loading: false,
                    homePageData: responses[0].data?.data,
                    carouselData: responses[1].data?.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
        window.scrollTo(0, 0);
    }

    render() {
        const { homePageData, carouselData, loading, error } = this.state;
        const { sponsorsExist } = this.props;

        if (loading) {
            return (
                <LoadingSpinner classes='w-full h-96 flex justify-center items-center' />
            );
        }

        if (error) {
            return (
                <div className='w-full h-96 flex justify-center items-center'>
                    <h3 className='text-red'>An error has occurred</h3>
                </div>
            );
        }

        const information = homePageData?.attributes?.information;

        return (
            <div className='flex flex-row flex-wrap justify-items-center mx-auto'>
                <HeroMedia homePageData={homePageData} />
                <MainCarousel carouselData={carouselData} />
                {sponsorsExist && (
                    <div className='w-full px-4 my-4 mx-auto'>
                        <Sponsors />
                    </div>
                )}
                <HomePageInformation info={information} />
                <PartnersCarousel />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sponsorsExist: state.state.sponsorsExist,
    };
};

export default connect(mapStateToProps)(HomePage);
