// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Disclosure } from '@headlessui/react';

// Components
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Sponsors from '../../components/Sponsors/Sponsors';

// Services, data, and media
import { getFullWrittenDate } from '../../utils/datesAndTimes';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { isNullUndefined } from '../../utils/general';

const resultsDisplay = (props) => {
    const {
        resultsGrouped,
        monthDisclosures,
        loading,
        error,
        selectedYear,
        sponsorsExist,
    } = props;

    if (loading) {
        return <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />;
    }

    if (error) {
        return (
            <div className='flex justify-center pt-12 lg:pt-48'>
                <h3>An error has occurred!</h3>
            </div>
        );
    }

    if (!isNullUndefined(resultsGrouped) && resultsGrouped.size > 0) {
        const months = [];
        resultsGrouped.forEach((eventIdMap, month) => {
            const events = [];
            eventIdMap.forEach((divisionsStatsMap, eventId) => {
                let eventMetadata = null;
                const divisions = [];
                divisionsStatsMap
                    .get('divisions')
                    .forEach((resultsArray, division) => {
                        // Get event info from first result in result array
                        eventMetadata = resultsArray[0];
                        // Sort results alphabetically by link name
                        const sortedResultsArray = resultsArray.sort(
                            (result1, result2) => {
                                const result1LinkName =
                                    result1.attributes.link_name;
                                const result2LinkName =
                                    result2.attributes.link_name;
                                if (
                                    !isNullUndefined(result1LinkName) &&
                                    !isNullUndefined(result2LinkName) &&
                                    result1LinkName < result2LinkName
                                ) {
                                    return -1;
                                } else if (
                                    !isNullUndefined(result1LinkName) &&
                                    !isNullUndefined(result2LinkName) &&
                                    result1LinkName > result2LinkName
                                ) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }
                        );
                        const results = [];
                        sortedResultsArray.forEach((result) => {
                            const { result_id, link, link_name, credit } =
                                result.attributes;
                            if (
                                isNullUndefined(result_id) ||
                                isNullUndefined(link_name)
                            ) {
                                return;
                            }
                            results.push(
                                <li key={result.id} className='hover:underline'>
                                    {link && link !== '' ? (
                                        <a
                                            href={link}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            Results by {credit}
                                        </a>
                                    ) : (
                                        <Link
                                            to={{
                                                pathname: `/results/${result_id}`,
                                                state: {
                                                    showResultsNavigation: true,
                                                },
                                            }}
                                        >
                                            {link_name}
                                        </Link>
                                    )}
                                </li>
                            );
                        });
                        divisions.push(<ul key={division}>{results}</ul>);
                    });

                const stats = [];
                const statsArray = divisionsStatsMap.get('stats');
                if (isNullUndefined(eventMetadata) && statsArray.length > 0) {
                    eventMetadata = statsArray[0];
                }
                // Sort stats alphabetically by link name
                const sortedStatsArray = statsArray.sort((stat1, stat2) => {
                    const stat1LinkName = stat1.attributes.link_name;
                    const stat2LinkName = stat2.attributes.link_name;
                    if (
                        !isNullUndefined(stat1LinkName) &&
                        !isNullUndefined(stat2LinkName) &&
                        stat1LinkName < stat2LinkName
                    ) {
                        return -1;
                    } else if (
                        !isNullUndefined(stat1LinkName) &&
                        !isNullUndefined(stat2LinkName) &&
                        stat1LinkName > stat2LinkName
                    ) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                sortedStatsArray.forEach((stat) => {
                    const { result_id, link_name } = stat.attributes;
                    if (
                        isNullUndefined(result_id) ||
                        isNullUndefined(link_name)
                    ) {
                        return;
                    }
                    stats.push(
                        <li key={stat.id}>
                            <Link
                                to={{
                                    pathname: `/results/${result_id}`,
                                    state: {
                                        showResultsNavigation: true,
                                    },
                                }}
                                className='hover:underline'
                            >
                                {link_name}
                            </Link>
                        </li>
                    );
                });

                const { event_name, race_date } = eventMetadata.attributes;
                if (isNullUndefined(event_name) || isNullUndefined(race_date)) {
                    return;
                }

                events.push(
                    <div key={eventId}>
                        <h4 className='font-bold'>{event_name}</h4>
                        <p className='italic'>
                            {getFullWrittenDate(race_date)}
                        </p>
                        {divisions.length > 0 ? <div>{divisions}</div> : null}
                        {stats.length > 0 ? (
                            <>
                                <h5 className='font-bold'>Other Stats</h5>
                                <ul>{stats}</ul>
                            </>
                        ) : null}
                    </div>
                );
            });

            months.push(
                <Disclosure key={month}>
                    <Disclosure.Button as='div' className='appearance-none'>
                        <div
                            className='hover:cursor-pointer flex items-center justify-between'
                            onClick={() => props.toggleDisclosure(month)}
                        >
                            <h2 className='font-bold'>{month}</h2>
                            {monthDisclosures[month] ? (
                                <ChevronUpIcon
                                    className='h-8 w-8 heroicon-stroke-w-3'
                                    aria-hidden='true'
                                />
                            ) : (
                                <ChevronDownIcon
                                    className='h-8 w-8 heroicon-stroke-w-3'
                                    aria-hidden='true'
                                />
                            )}
                        </div>
                    </Disclosure.Button>
                    {monthDisclosures[month] ? (
                        <Disclosure.Panel static>
                            <div className='space-y-3'>{events}</div>
                        </Disclosure.Panel>
                    ) : null}
                    <hr className='my-2 border-gray-300' />
                </Disclosure>
            );
        });

        // Split months array into 2 so we can display ads in between
        const months1 = months.slice(0, 1);
        const months2 = months.slice(1);

        return (
            <>
                <div className='space-y-3 w-full mx-auto p-4 lg:pb-0'>
                    {months1}
                </div>
                {sponsorsExist && (
                    <div className='w-full lg:hidden px-4'>
                        <Sponsors />
                    </div>
                )}
                <div className='space-y-3 w-full mx-auto p-4'>{months2}</div>
            </>
        );
    }
    return (
        <div className='w-full mx-auto py-4 text-center'>
            <h3 className='mb-4'>No results for {selectedYear}</h3>
            {sponsorsExist && (
                <div className='w-full lg:hidden px-4'>
                    <Sponsors />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        sponsorsExist: state.state.sponsorsExist,
    };
};

export default connect(mapStateToProps)(resultsDisplay);
