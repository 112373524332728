// Libraries
import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

// Components
import DataTableDetail from '../../components/DataTableDetail/DataTableDetail';
import DataTableFilter from '../../components/DataTableFilter/DataTableFilter';
import PartnersCarousel from '../../components/PartnersCarousel/PartnersCarousel';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Sponsors from '../../components/Sponsors/Sponsors';

// Services, data, and media
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { getResult } from '../../services/resultsService';
import { getFullWrittenDate } from '../../utils/datesAndTimes';
import { generateDataTablesColumns } from '../../utils/dataTablesColumns';
import { isNullUndefined } from '../../utils/general';
const gfm = require('remark-gfm');

class ResultPage extends Component {
    state = {
        loading: true,
        error: false,
        resultData: {},
        showResultsNavigation: false,
    };

    componentDidMount() {
        let showResultsNavigation = false;
        if (this.props.location.state) {
            showResultsNavigation =
                this.props.location.state.showResultsNavigation;
            if (showResultsNavigation) {
                this.setState({ showResultsNavigation });
            }
        }
        this.getResultData(this.props.match.params.id);
        window.scrollTo(0, 0);
    }

    onBack = () => {
        this.props.history.push({
            pathname: '/results',
            state: {
                loadFromRedux: true,
            },
        });
    };

    getResultData(id) {
        getResult(id)
            .then((response) => {
                this.setState({
                    loading: false,
                    resultData: response.data.data,
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    error: true,
                });
            });
    }

    render() {
        const { resultData, loading, error, showResultsNavigation } =
            this.state;

        const { sponsorsExist } = this.props;

        if (loading) {
            return (
                <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />
            );
        }
        if (error || isNullUndefined(resultData)) {
            return (
                <div className='flex justify-center pt-12 lg:pt-48'>
                    <h3>An error has occurred!</h3>
                </div>
            );
        }

        const {
            sections,
            title,
            report_name,
            location,
            race_date,
            info_message,
            inaugural_race_year,
            course_info,
            course_used_in_years,
            notes,
            weather,
        } = resultData.attributes;

        let dataTableSections = null;
        if (!isNullUndefined(sections)) {
            dataTableSections = sections.map((section, idx) => {
                if (
                    isNullUndefined(section.columns) ||
                    isNullUndefined(section.data)
                ) {
                    return null;
                }
                const newColumns = generateDataTablesColumns(
                    section.columns,
                    section.data
                );

                // Pass columns data so the ResultsDetail component can sort fields by order
                const columnsPropsObj = {
                    columns: section.columns,
                };

                return (
                    <DataTableFilter
                        key={String(section.header_name) + idx}
                        section={section}
                        columns={newColumns}
                        expandableRows={true}
                        expandableRowsComponent={DataTableDetail}
                        expandableRowsComponentProps={columnsPropsObj}
                    />
                );
            });
        }

        return (
            <>
                <div className='w-full mx-auto p-4 max-w-7xl'>
                    {!isNullUndefined(showResultsNavigation) ? (
                        <div
                            className='flex items-center cursor-pointer mb-2'
                            onClick={this.onBack}
                        >
                            <ArrowLeftIcon className='h-8 w-8 heroicon-stroke-w-2.5 text-gray-500' />
                            <span className='ml-2 font-serif font-semibold text-lg text-gray-500'>
                                ALL RESULTS
                            </span>
                        </div>
                    ) : null}
                    <div className='prose dark:prose-invert max-w-5xl'>
                        {!isNullUndefined(title) ? <h1>{title}</h1> : null}
                        <h3 className='lg:hidden'>
                            {!isNullUndefined(report_name) ? (
                                <span>
                                    {report_name}
                                    <br />
                                </span>
                            ) : null}
                            {!isNullUndefined(location) ? (
                                <span>
                                    {location}
                                    <br />
                                </span>
                            ) : null}
                            {!isNullUndefined(race_date) ? (
                                <span>{getFullWrittenDate(race_date)}</span>
                            ) : null}
                        </h3>
                        <h3 className='hidden lg:block'>
                            {!isNullUndefined(report_name) ? (
                                <span>
                                    {report_name}
                                    &emsp;&bull;&emsp;
                                </span>
                            ) : null}
                            {!isNullUndefined(location) ? (
                                <span>{location}&emsp;&bull;&emsp;</span>
                            ) : null}
                            {!isNullUndefined(race_date) ? (
                                <span>{getFullWrittenDate(race_date)}</span>
                            ) : null}
                        </h3>
                        {!isNullUndefined(info_message) &&
                        info_message !== '' ? (
                            <ReactMarkdown
                                children={info_message}
                                remarkPlugins={[gfm]}
                                linkTarget={(href, children, title) =>
                                    href.includes('http') ? '_blank' : null
                                }
                            />
                        ) : null}
                        {!isNullUndefined(inaugural_race_year) &&
                        inaugural_race_year !== '' ? (
                            <p>
                                <strong>Inaugural Race Year: </strong>
                                {inaugural_race_year}
                            </p>
                        ) : null}
                        {!isNullUndefined(course_used_in_years) &&
                        course_used_in_years !== '' ? (
                            <p>
                                <strong>Course Used in Years: </strong>
                                {course_used_in_years}
                            </p>
                        ) : null}
                        {!isNullUndefined(course_info) && course_info !== '' ? (
                            <p>
                                <strong>Course Information: </strong>
                                {course_info}
                            </p>
                        ) : null}
                        {!isNullUndefined(notes) && notes !== '' ? (
                            <p>
                                <strong>Notes: </strong>
                                {notes}
                            </p>
                        ) : null}
                        {!isNullUndefined(weather) && weather !== '' ? (
                            <p>
                                <strong>Weather: </strong>
                                {weather}
                            </p>
                        ) : null}
                    </div>
                </div>
                {sponsorsExist && (
                    <div className='w-full px-4 mx-auto'>
                        <Sponsors />
                    </div>
                )}
                {!isNullUndefined(sections) ? (
                    <div className='w-full mx-auto space-y-2 py-2'>
                        {dataTableSections}
                    </div>
                ) : null}
                <PartnersCarousel />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sponsorsExist: state.state.sponsorsExist,
    };
};

export default connect(mapStateToProps)(ResultPage);
