// Libraries
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Components
import RunningGroupCard from './RunningGroupCard';
import ListBox from '../../components/ListBox/ListBox';
import PartnersCarousel from '../../components/PartnersCarousel/PartnersCarousel';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Sponsors from '../../components/Sponsors/Sponsors';

// Services, data, and media
import { getRunningGroups } from '../../services/runningGroupsService';
import { dayNames } from '../../utils/datesAndTimes';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { classNames, isNullUndefined } from '../../utils/general';

class RunningGroupsPage extends Component {
    state = {
        loading: true,
        error: false,
        runningGroupsData: [],
        runningGroupsFiltered: [],
        selectedDay: 'All Days',
        availableDays: ['All Days', ...dayNames],
    };

    componentDidMount() {
        getRunningGroups()
            .then((response) => {
                this.setState({
                    loading: false,
                    runningGroupsData: response.data.data,
                    runningGroupsFiltered: response.data.data,
                });
            })
            .catch((error) => {
                this.setState({ loading: false, error: true });
            });
        window.scrollTo(0, 0);
    }

    setDayFilter = (day) => {
        const { runningGroupsData } = this.state;
        let runningGroupsFiltered;
        if (day === 'All Days') {
            runningGroupsFiltered = runningGroupsData;
        } else {
            runningGroupsFiltered = runningGroupsData.filter((group) => {
                const { days } = group.attributes;
                if (!isNullUndefined(days) && days !== '') {
                    return days.toLowerCase().includes(day.toLowerCase());
                }
                return true;
            });
        }
        this.setState({
            selectedDay: day,
            runningGroupsFiltered: runningGroupsFiltered,
        });
    };

    render() {
        const {
            loading,
            error,
            runningGroupsFiltered,
            selectedDay,
            availableDays,
        } = this.state;

        const { sponsorsExist } = this.props;

        if (loading) {
            return (
                <LoadingSpinner classes='flex justify-center pt-12 lg:pt-48' />
            );
        }
        if (error) {
            return (
                <div className='flex justify-center pt-12 lg:pt-48'>
                    <h3>An error has occurred!</h3>
                </div>
            );
        }

        const runningGroups = runningGroupsFiltered.map((item, idx) => (
            <RunningGroupCard key={item.id} info={item} />
        ));

        // Split runningGroups array into 2 so we can display ads in between
        const runningGroups1 = runningGroups.slice(0, 6);
        const runningGroups2 = runningGroups.slice(6);

        return (
            <>
                <div className='flex max-w-7xl mx-auto'>
                    <div
                        className={classNames(
                            'w-full',
                            sponsorsExist && 'lg:w-2/3'
                        )}
                    >
                        <div className='flex flex-wrap justify-between items-center mb-2 pt-4 px-4'>
                            <h1 className='text-4xl font-bold py-2 mr-2'>
                                Running Groups
                            </h1>
                            <div className='flex flex-wrap items-center -ml-2'>
                                <div className='relative w-52 h-14 py-2 mx-2'>
                                    <ListBox
                                        options={availableDays}
                                        onChange={this.setDayFilter}
                                        selectedOption={selectedDay}
                                        widthClass='w-44'
                                    />
                                </div>
                                <Link
                                    to='/running-groups/add'
                                    className='flex text-gray-50 dark:text-gray-900 bg-gray-700 dark:bg-gray-300 px-3 py-2 rounded-md text-base font-serif font-semibold no-underline mx-2'
                                >
                                    ADD GROUP
                                    <div className='w-6 ml-2'>
                                        <PlusCircleIcon
                                            className='h-6 w-6'
                                            aria-hidden='true'
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <hr className='mb-2 border-gray-300 dark:border-gray-700 px-4' />
                        {runningGroups.length > 0 ? (
                            <>
                                <div className='flex flex-wrap w-full max-w-7xl mx-auto'>
                                    {runningGroups1}
                                </div>
                                {sponsorsExist && (
                                    <div className='w-full lg:hidden px-4 my-4'>
                                        <Sponsors />
                                    </div>
                                )}
                                <div className='flex flex-wrap w-full max-w-7xl mx-auto'>
                                    {runningGroups2}
                                </div>
                            </>
                        ) : (
                            <>
                                <h4 className='text-center my-4'>
                                    No running groups on {selectedDay}
                                </h4>
                                {sponsorsExist && (
                                    <div className='w-full lg:hidden px-4'>
                                        <Sponsors />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    {sponsorsExist && (
                        <div className='hidden lg:block w-1/3 p-4'>
                            <Sponsors vertical={true} />
                        </div>
                    )}
                </div>
                <PartnersCarousel />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sponsorsExist: state.state.sponsorsExist,
    };
};

export default connect(mapStateToProps)(RunningGroupsPage);
